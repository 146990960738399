import React from "react"
import Article from "../../components/layout/articleRenderer"
import useIllumPlan from "../../hooks/use-illumination-plan"
import { css } from "@emotion/react"

const wrapperStyles = css`
  .wp-block-embed {
    max-width: 420px;
    margin: 10px auto;
  }

  .wp-block-embed > div {
    position: relative;
    padding-bottom: 75%;
    height: 0px;
  }

  .wp-block-embed iframe {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
  }

  .wp-block-image {
    display: flex;
    flex-flow: column;
    margin: 0 auto;
  
    img {
      background: lightgray;
      width: 100%;
      height: auto;
    }
  }

  .videofiles {
      background: gray;
      padding: 0 10px;
      .wp-block-image {
              padding: 10px 0;
              height: 100%;
              img {
                  height: 100%!important;
              }
      }
  }


  .wp-block-columns {
    display: flex;
    max-width: 100%;
    justify-content: center;
    margin: 0 -10px;
      @media (max-width: 768px) {
        flex-direction: column;
        margin: 0;
      }
  .wp-block-column {
    position: relative;
    flex-basis: 25%;
    
    margin: 10px;
          @media (max-width: 768px) {
        margin: 0 0 10px 0;
      }
    .wp-block-file {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 90;
      font-size: 1.8rem;
      a {
        display: block;
        width: 100%;
        height: 100%;
        color: white;
        text-align: right;
      }
    }
  }

  .wp-block-image {

    img {
      width: 100%;
      height: auto;
    }
  }
`

export default function IlluminationPlan() {
  const genPlanData = useIllumPlan()

  return (
    <Article
      wordPressArticle={genPlanData}
      contentWrapperStyles={wrapperStyles}
    />
  )
}
